<script setup lang="ts">
import { useStore } from 'vuex'

const props = withDefaults(defineProps<{
  title?: string
  subtitle?: string
  width?: string
  header?: boolean
  topActions?: boolean
  footer?: boolean
  transparent?: boolean
}>(), {
  title: '',
  subtitle: '',
  width: '460px',
  // height: '',
  header: true,
  topActions: false,
  footer: false,
  transparent: false, // Drop the background color to enable floating elements.
})
const emit = defineEmits(['close'])

const store = useStore()

const containerClasses = computed(() => ({
  'transparent': props.transparent,
  'no-header': !props.header,
}))
const containerStyle = computed(() => ({
  width: props.width,
  // height: props.height,
}))

function closeModal() {
  emit('close')
}
function bodyClick(event: Event) {
  if (props.transparent && (event?.target as HTMLElement)?.className === 'modal-body') {
    closeModal()
  }
}

onMounted(() => store.dispatch('updateUIx', { dialogOpen: true }))
onBeforeUnmount(() => store.dispatch('updateUIx', { dialogOpen: false }))
</script>

<template>
  <Teleport to="#wrapper">
    <div>
      <div class="modal-mask" @click="closeModal()">
        <div class="modal-wrapper">
          <div class="modal-outer-container">
            <div
              class="modal-container"
              :class="containerClasses"
              :style="containerStyle"
              @click.stop
            >
              <!-- Header -->
              <div
                v-if="header"
                class="modal-header"
              >
                <slot name="header">
                  <div class="title-container">
                    <slot name="title">
                      <h3 class="modal-title">
                        {{ title }}
                      </h3>
                    </slot>
                    <a class="close-button" @click="closeModal()">
                      <Icon name="cross" />
                    </a>
                  </div>
                  <slot name="modal-subtitle">
                    <h4 v-if="subtitle" class="subtitle">
                      {{ subtitle }}
                    </h4>
                  </slot>
                </slot>
              </div>

              <!-- Actions -->
              <slot name="top-actions-container">
                <div
                  v-if="topActions"
                  class="modal-top-actions"
                >
                  <slot name="top-actions" />
                </div>
              </slot>

              <!-- Body -->
              <div class="modal-body" @click="bodyClick($event)">
                <slot />
              </div>

              <!-- Footer -->
              <div
                v-if="footer"
                class="modal-footer"
              >
                <slot name="footer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 9998;
  top: 0;
  left: 0;
  right: 0;
  bottom: env(safe-area-inset-bottom);
  background-color: rgba(47, 51, 49, 0.85);
  transition: opacity 0.3s ease;
  backdrop-filter: blur(7.5px); // 7.5
  overscroll-behavior: contain !important;
  .modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: calc(var(--ih) - env(safe-area-inset-bottom));
    max-height: 100%;
    overflow: visible; // scroll;
    overscroll-behavior: contain !important;
    box-sizing: content-box;
    .modal-outer-container {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .modal-container {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      width: fit-content; // Fix for Safari
      max-width: calc(100vw - 1.5rem);
      height: 100vh; // IMPORTANT! No idea why, but this is the only way iOS works.
      max-height: calc(100% - 1.5rem);
      margin: 0.75rem auto;
      color: hsl(var(--text));
      border-radius: $border-radius-xl;
      box-sizing: border-box;
      transition: all 0.3s ease;
      overscroll-behavior: contain !important;
      @media #{$tablet}, #{$desktop} {
        height: fit-content;
      }
      &.transparent {
        :deep(.modal-title) {
          color: hsl(var(--base)) !important;
        }
        .close-button {
          color: hsl(var(--base)) !important;
        }
        .theme--dark {
          .modal-title {
            color: hsl(var(--text)) !important;
          }
          .close-button {
            color: hsl(var(--text)) !important;
          }
        }
      }
      &.no-header {
        .modal-body {
          padding-top: 1.5rem;
        }
      }
      &:not(.transparent) {
        background-color: hsl(var(--base));
        box-shadow: var(--shadow-modal);
      }
      .modal-header {
        margin-bottom: 1.5rem;
        padding: 1.5rem 1.5rem 0 1.5rem;
        .title-container {
          display: flex;
          align-items: center;
          :deep(.modal-title) {
            flex: 1;
            margin: 0 !important;
            font-size: 1.6rem;
          }
          .close-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            text-align: center;
            font-size: 1.4rem;
          }
        }
        .modal-subtitle {
          margin-top: .5rem;
          margin-bottom: 0;
          color: hsl(var(--text-dim)) !important;
          font-weight: normal;
        }
      }
      .modal-top-actions {
        margin-bottom: 1.5rem;
        padding: .5rem 1.5rem;
        background-color: hsl(var(--surface));
        :deep(.button) {
          background-color: hsl(var(--item));
          border: 1px solid hsl(var(--border));
          &:not(:first-child) {
            margin-left: .5rem;
          }
        }
      }
      .modal-body {
        padding: 0 1.5rem 1.5rem 1.5rem;
        overflow-y: auto;
      }
      .modal-footer {
        padding: 0 1.5rem 1.5rem 1.5rem;
        :deep(.dialog-actions) {
          text-align: right;
          .button {
            &:not(:last-child) {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
